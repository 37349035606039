/* Button.css */
.underline-effect {
  position: relative;
}

.underline-effect::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: #f97316; /* Tailwind's orange-500 */
  transition: width 0.3s ease, left 0.3s ease;
}

.underline-effect:hover::after {
  width: 100%;
  left: 0;
}
#home-img {
  width: 400px; /* Adjust the size as needed */
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
}
